<template>
  <section id="mission" class="mission section">
    <div class="container">
      <div class="mission__wrapper">
        <div class="mission__content">
          <h2 class="mission__title h1 heading">
            We’re partnering with startups and enterprises to
            <strong>create profitable digital products and ventures.</strong>
          </h2>
          <br />
          <p class="mission__text text">
            By combining heavy web development expertise, with a relentless
            focus on planning and execution processes, we are able to develop
            unique products quickly,
            <em>
              <strong> delivering reliable product experiences. </strong>
            </em>
          </p>
        </div>
        <div class="mission__photo-wrapper">
          <div class="mission__photo">
            <frame :parallax-speed="2" />
            <LazyImgWrapper
              image-path="mission"
              :width="485"
              :height="744"
              image-alt="Photo of a Never Settle poster"
              sizes="(max-width: 640px) 80vw,(max-width: 970px) 40vw, 30vw"
              :not-lazy="true"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Mission',
}
</script>
