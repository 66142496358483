<template>
  <article class="section network">
    <div class="container">
      <article class="network__header">
        <h2 class="heading h1">
          A unique
          <strong>global network</strong>
        </h2>
        <p class="subheading">
          Our own team is accompanied by an external network of experts. Our
          network includes some of the best web developers, engineers, designers
          and advisors.
          <br />
          <strong>An extraordinary resource to tap into when necessary.</strong>
        </p>
      </article>
      <div class="features--alternative">
        <div class="columns">
          <div class="column is-five is-twelve-small">
            <article class="feature--alternative">
              <header class="feature__header">
                <img
                  :src="require('~/assets/images/apps.svg')"
                  class="feature__icon"
                  alt="We work with the best in mobile app development"
                  width="111"
                  height="110"
                  data-not-lazy="true"
                />
                <div class="feature__header-text">
                  <h3 class="feature__subtitle">We work with the best in</h3>
                  <h2 class="feature__title">App development</h2>
                </div>
              </header>
              <p>
                Our network includes proficient <em>app developers</em> for
                every platform, whether it's <em>iOS</em>, <em>Android</em> or a
                cross-platform application.
              </p>
              <p>
                We can assist you create a detailed product roadmap for your
                concept and build an
                <em>MVP</em> or a full-fledged app, that meets your
                expectations.
              </p>
            </article>
          </div>
          <div
            class="column is-five is-twelve-small is-offset-two no-offset-small"
          >
            <article class="feature--alternative">
              <header class="feature__header">
                <img
                  :src="require('~/assets/images/ux.svg')"
                  class="feature__icon"
                  alt="We're work with experts in UI and UX design"
                  width="117"
                  height="110"
                  data-not-lazy="true"
                />
                <div class="feature__header-text">
                  <h3 class="feature__subtitle">We work with experts in</h3>
                  <h2 class="feature__title">UX and UI design</h2>
                </div>
              </header>
              <p>
                Our network includes UX and UI pros who embed
                <em>freshness</em> and <em>familiarity</em> in your product
                through (un)common design patterns.
              </p>
              <p>
                We create eye-catching visuals and
                <em>emotion driven</em> designs that help you stand-out. Most
                important: we create designs that
                <em class="h-case-uppercase">convert</em>.
              </p>
            </article>
          </div>
        </div>
        <hr class="divider" />
        <div class="features__call-to-action">
          <p>
            Making great products is why we get up in the morning. With an
            obsession for well-developed products and a relentless attention to
            detail,
            <em>we will always put the product first.</em>
          </p>
          <a
            v-smooth-scroll
            href="#contact"
            class="button"
            @click="$gtag.event('cta', 'click', 'Let\'s Talk Network')"
          >
            <span class="button__inner">Contact Us</span>
          </a>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Network',
}
</script>
