<template>
  <div class="partners-wrapper">
    <div class="partners-intro">
      <h2 class="heading">
        We are trusted partners to some of the
        <br />
        <strong>companies that move the world</strong>
      </h2>
    </div>
    <section class="partners">
      <partner
        v-for="(partner, index) in partners"
        :key="index"
        :text="partner.text"
        :alt="partner.alt"
        :image="partner.image"
        :width="partner.width"
        :height="partner.height"
      />
    </section>
  </div>
</template>

<script>
import Partner from './Partner'

export default {
  name: 'Partners',
  components: {
    partner: Partner,
  },
  data() {
    return {
      partners: [
        {
          alt: 'Logo of The United Nations',
          text: 'The United Nations',
          image: require('~/assets/images/partners/united-nations.svg'),
          width: 59,
          height: 50,
        },
        {
          alt: 'Logo of NTT Data - Global software outsourcing',
          text: 'Global software outsourcing partner',
          image: require('~/assets/images/partners/ntt-data.svg'),
          width: 131,
          height: 19,
        },
        {
          alt: 'Logo of Flyeralarm - Industy leading print services',
          text: 'Industy leading print services',
          image: require('~/assets/images/partners/flyeralarm.svg'),
          width: 29,
          height: 50,
        },
        {
          alt: 'Logo of Virgin Media - Global telecomunication services',
          text: 'Global telecomunication services',
          image: require('~/assets/images/partners/virgin-media.svg'),
          width: 82,
          height: 50,
        },
        {
          alt: 'Logo of Travel Keys - Industry leading luxury travel broker',
          text: 'Top luxury travel broker',
          image: require('~/assets/images/partners/travel-keys.svg'),
          width: 56,
          height: 42,
        },
        {
          alt: 'Logo of Trafalgar - Global event cinema distribution',
          text: 'Global event cinema distribution',
          image: require('~/assets/images/partners/trafalgar.svg'),
          width: 110,
          height: 21,
        },
        {
          alt: 'Logo of Silver Fern - Software greenhouse IOT startup',
          text: 'Software greenhouse IOT startup',
          image: require('~/assets/images/partners/silver-fern.svg'),
          width: 109,
          height: 58,
        },
        {
          alt: "Logo of County Supplies - London's top fruit & vegetable wholesaler ",
          text: "London's top fruit & vegetable wholesaler ",
          image: require('~/assets/images/partners/county-supplies.svg'),
          width: 132,
          height: 40,
        },
        {
          alt: 'Logo of Luxgive - Novel travel startup',
          text: 'Novel travel startup',
          image: require('~/assets/images/partners/luxgive.svg'),
          width: 111,
          height: 21,
        },
        {
          alt: 'Logo of Friday Pulse - Behavioural psychology pioneer',
          text: 'Behavioural psychology pioneer',
          image: require('~/assets/images/partners/friday-pulse.svg'),
          width: 102,
          height: 43,
        },
        {
          alt: 'Logo of Nic Marks - The mind behind the Happy Planet Index',
          text: 'The mind behind the Happy Planet Index',
          image: require('~/assets/images/partners/nic-marks.svg'),
          width: 114,
          height: 17,
        },
        {
          alt: 'Logo of DearthGalat - Legal tax and finances services',
          text: 'Legal tax and finances services',
          image: require('~/assets/images/partners/dearthgalat.svg'),
          width: 135,
          height: 26,
        },
      ],
    }
  },
}
</script>
