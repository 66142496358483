<template>
  <section
    id="about"
    class="section section--services h-no-padding-top h-z-index-1"
  >
    <article class="editor">
      <div class="editor__header">
        <div class="editor__option-wrapper">
          <span class="editor__option -type-close" />
          <span class="editor__option -type-minimize" />
          <span class="editor__option -type-maximize" />
        </div>
      </div>
      <div class="features">
        <div class="columns">
          <div class="column is-five is-twelve-medium">
            <article class="feature">
              <header class="feature__header">
                <img
                  :src="require('~/assets/images/frontend.svg')"
                  class="feature__icon"
                  alt="We're experts in web development"
                  width="181"
                  height="180"
                  data-not-lazy="true"
                />
                <h3 class="feature__subtitle">We deliver</h3>
                <h2 class="feature__title">
                  Qualitative front-end experiences
                </h2>
              </header>
              <p>
                Our process will guide you to your best decisions, from refining
                and expanding your ideas to shipping your product to customers.
              </p>
              <p>
                Working with us means teaming up with passionate experts who
                will <em>prototype</em>, <em>design</em> and <em>build</em> your
                product.
              </p>
              <p>
                <em>Functional. Responsive. Reliable.</em> This is our standard
                of excellence.
              </p>
              <p>
                Our front-end framework of choice is <em>VueJS</em>, but we're
                fluent in many others.
              </p>
              <hr class="divider h-show-medium-down" />
            </article>
          </div>
          <div
            class="
              column
              is-five is-twelve-medium is-offset-two
              no-offset-medium
            "
          >
            <article class="feature">
              <header class="feature__header">
                <img
                  :src="require('~/assets/images/backend.svg')"
                  class="feature__icon"
                  width="182"
                  height="182"
                  alt="We're enthusiasts about back-end development"
                  data-not-lazy="true"
                />
                <h3 class="feature__subtitle">We build</h3>
                <h2 class="feature__title">Scalable back-end platforms</h2>
              </header>
              <p>
                We create products that behave flawlessly on each interaction,
                with an excellent performance under pressure.
              </p>
              <p>
                Your product will be engineered on a solid foundation, that
                helps you scale while keeping your application agile and your
                costs in check.
              </p>
              <p>The end goal is to deliver <em>work we're proud of.</em></p>
              <p>
                Our back-end languages of choice are: <em>PHP (Laravel)</em>,
                <em>Ruby</em>, or <em>WordPress</em>.
              </p>
            </article>
          </div>
        </div>
        <hr class="divider h-margin-bottom-4" />
        <div class="features__call-to-action">
          <a href="https://clutch.co/profile/graffino#reviews" target="_blank">
            <img
              :src="clutchBadge"
              width="144"
              height="144"
              type="assets/png"
              class="feature__badge"
              alt="We're experts in web development"
              data-not-lazy="true"
            />
          </a>
          <p class="text">
            <strong class="text -color-gray"
              >We're trained web architects, front-end and back-end developers,
              using technology to build your business.</strong
            >
          </p>
          <a
            v-smooth-scroll
            href="#contact"
            class="button"
            @click="$gtag.event('cta', 'click', 'Let\'s Talk Services')"
          >
            <span class="button__inner">Contact Us</span>
          </a>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
const clutchBadge = require('~/assets/images/clutch-badge.svg')

export default {
  name: 'Mission',
  data() {
    return { clutchBadge }
  },
}
</script>
