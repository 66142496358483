var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"section network"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"features--alternative"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-five is-twelve-small"},[_c('article',{staticClass:"feature--alternative"},[_c('header',{staticClass:"feature__header"},[_c('img',{staticClass:"feature__icon",attrs:{"src":require('~/assets/images/apps.svg'),"alt":"We work with the best in mobile app development","width":"111","height":"110","data-not-lazy":"true"}}),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3)])]),_vm._v(" "),_c('div',{staticClass:"column is-five is-twelve-small is-offset-two no-offset-small"},[_c('article',{staticClass:"feature--alternative"},[_c('header',{staticClass:"feature__header"},[_c('img',{staticClass:"feature__icon",attrs:{"src":require('~/assets/images/ux.svg'),"alt":"We're work with experts in UI and UX design","width":"117","height":"110","data-not-lazy":"true"}}),_vm._v(" "),_vm._m(4)]),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6)])])]),_vm._v(" "),_c('hr',{staticClass:"divider"}),_vm._v(" "),_c('div',{staticClass:"features__call-to-action"},[_vm._m(7),_vm._v(" "),_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],staticClass:"button",attrs:{"href":"#contact"},on:{"click":function($event){return _vm.$gtag.event('cta', 'click', 'Let\'s Talk Network')}}},[_c('span',{staticClass:"button__inner"},[_vm._v("Contact Us")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"network__header"},[_c('h2',{staticClass:"heading h1"},[_vm._v("\n        A unique\n        "),_c('strong',[_vm._v("global network")])]),_vm._v(" "),_c('p',{staticClass:"subheading"},[_vm._v("\n        Our own team is accompanied by an external network of experts. Our\n        network includes some of the best web developers, engineers, designers\n        and advisors.\n        "),_c('br'),_vm._v(" "),_c('strong',[_vm._v("An extraordinary resource to tap into when necessary.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature__header-text"},[_c('h3',{staticClass:"feature__subtitle"},[_vm._v("We work with the best in")]),_vm._v(" "),_c('h2',{staticClass:"feature__title"},[_vm._v("App development")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n              Our network includes proficient "),_c('em',[_vm._v("app developers")]),_vm._v(" for\n              every platform, whether it's "),_c('em',[_vm._v("iOS")]),_vm._v(", "),_c('em',[_vm._v("Android")]),_vm._v(" or a\n              cross-platform application.\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n              We can assist you create a detailed product roadmap for your\n              concept and build an\n              "),_c('em',[_vm._v("MVP")]),_vm._v(" or a full-fledged app, that meets your\n              expectations.\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature__header-text"},[_c('h3',{staticClass:"feature__subtitle"},[_vm._v("We work with experts in")]),_vm._v(" "),_c('h2',{staticClass:"feature__title"},[_vm._v("UX and UI design")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n              Our network includes UX and UI pros who embed\n              "),_c('em',[_vm._v("freshness")]),_vm._v(" and "),_c('em',[_vm._v("familiarity")]),_vm._v(" in your product\n              through (un)common design patterns.\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n              We create eye-catching visuals and\n              "),_c('em',[_vm._v("emotion driven")]),_vm._v(" designs that help you stand-out. Most\n              important: we create designs that\n              "),_c('em',{staticClass:"h-case-uppercase"},[_vm._v("convert")]),_vm._v(".\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n          Making great products is why we get up in the morning. With an\n          obsession for well-developed products and a relentless attention to\n          detail,\n          "),_c('em',[_vm._v("we will always put the product first.")])])
}]

export { render, staticRenderFns }